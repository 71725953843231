// /*
//
// Array of object
//
// Top level object can be:
// 1. Header
// 2. Group (Group can have navItems as children)
// 3. navItem
//
// * Supported Options
//
// /--- Header ---/
//
// header
//
// /--- nav Grp ---/
//
// title
// icon (if it's on top level)
// tag
// tagVariant
// children
//
// /--- nav Item ---/
//
// icon (if it's on top level)
// title
// route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
// tag
// tagVariant
//
// */
// import dashboard from './dashboard'
// import appsAndPages from './apps-and-pages'
// import others from './others'
// import chartsAndMaps from './charts-and-maps'
import uiElements from './ui-elements'
// import formAndTable from './forms-and-table'

import cloudX from "@/navigation/vertical/cloudX/cloudX";
import database from "@/navigation/vertical/nextcart/database";
import legalities from "@/navigation/vertical/cloudX/legalities";
import order from "@/navigation/vertical/cloudX/order";
import methods from "@/navigation/vertical/cloudX/method";
import adsManage from "@/navigation/vertical/cloudX/adsManage";
import dodManage from "@/navigation/vertical/cloudX/dodManage";
import orderEx from "@/navigation/vertical/cloudX/orderEx";
import settings from "@/navigation/vertical/cloudX/settings";
import faq from "@/navigation/vertical/cloudX/faq";
import product from "@/navigation/vertical/cloudX/product";
import aboutUs from "@/navigation/vertical/cloudX/aboutUs";
import finance from "@/navigation/vertical/cloudX/finance";
import gateway from "@/navigation/vertical/cloudX/gateway";
import sellerShippingFinance from "@/views/pages/cloudx/shippingFinance/sellerShippingFinance";
import shippingChargeWithdrawal from "@/views/pages/cloudx/shippingFinance/shippingChargeWithdrawal";
import shippingWithdrawal from "@/navigation/vertical/cloudX/shippingWithdrawal";
import theme from "@/navigation/vertical/nextcart/theme";
import server from "@/navigation/vertical/nextcart/server";
import business from "@/navigation/vertical/nextcart/business";
import dayLimit from "@/navigation/vertical/nextcart/subscription";
import subscription from "@/navigation/vertical/nextcart/subscription";
import productSource from "@/navigation/vertical/nextcart/productSource";
import affiliate from "@/navigation/vertical/nextcart/affiliate";
import coupon from "@/navigation/vertical/nextcart/coupon";
import knowledgeBase from "@/navigation/vertical/nextcart/knowledgeBase";
import fxrate from "@/router/routes/nextcart/fxrate";
import fxRateSettings from "@/navigation/vertical/nextcart/fxRateSettings";
import Feature from "@/navigation/vertical/nextcart/feature";
import versionData from "@/navigation/vertical/nextcart/versionData";


export default [
    ...cloudX,
    ...database,
    ...theme,
    ...Feature,
    ...server,
    ...business,
    ...subscription,
    ...productSource,
    ...knowledgeBase,
    ...affiliate,
    ...coupon,
    ...fxRateSettings,
    ...versionData,
    // ...uiElements,

    ...product,
    ...dodManage,
    ...orderEx,
    ...order,
    ...finance,
    ...shippingWithdrawal,
    ...gateway,
    ...methods,
    ...adsManage,
    ...faq,
    ...settings,
    ...aboutUs,
    ...legalities,

]


    // ...dashboard, ...appsAndPages, ...uiElements, ...formAndTable, ...chartsAndMaps,,]

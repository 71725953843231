var menuItems = [];
var menu=[]
let items= [
    {
        title: 'Fx Rate Settings',
        route: 'fx-rate-settings',
        codename:'view_fxSettings'
    },

]
menu.push( {
    header: 'International Payment',
},)

menu.push({
    title: 'Fx Rate',
    icon: 'DollarSignIcon',
    children: items
})
// }
export default menu;

var menuItems = [];
var menu=[]
let items= [
    {
        title: 'Version Settings',
        route: 'version-data-settings',
        codename:'view_versionSettings'
    },

]
menu.push( {
    header: 'APP Version Management',
},)

menu.push({
    title: 'APP Version',
    icon: 'GitMergeIcon',
    children: items
})
// }
export default menu;
